export enum UserRole {
  ADMIN = "admin",
  STAFF = "staff",
  TECHNICIAN = "technician",
  BUILDER_CONTACT = "builder_contact",
  SITE_SUPER = "client",
  SITE_CONTACT = "site_contact",
  PARTNER = "partner",
}

export enum AppPermissions {
  BUILDERS_VIEW = "builders.view",
  BUILDER_CREATE = "builder.create",
  BUILDER_EDIT = "builder.edit",
  SCHEDULING_CALENDAR_VIEW = "scheduling.calendar.view",
  SCHEDULING_PROGRESS_VIEW = "scheduling.progress.view",
  SCHEDULING_RESCHEDULE_EDIT = "scheduling.reschedule.edit",
  SCHEDULING_RESCHEDULE_VIEW = "scheduling.reschedule.view",
  BILLING_LOOKUP_VIEW = "billing.lookup.view",
  USERS_VIEW = "users.view",
  USER_CREATE = "user.create",
  USER_EDIT = "user.edit",
  SITES_VIEW = "sites.view",
  SITE_CREATE = "site.create",
  SITE_EDIT = "site.edit",
  SITE_DETAILS_VIEW = "site.details.view",
  SITE_CONTACTS_VIEW = "site.contacts.view",
  SITE_CONTACTS_CREATE = "site.contacts.create",
  SITE_CONTACTS_DELETE = "site.contacts.delete",
  SITE_CONTACTS_EDIT = "site.contacts.edit",
  SITE_CONTRACTS_VIEW = "site.contracts.view",
  SITE_CONTRACTS_CREATE = "site.contracts.create",
  SITE_CONTRACTS_DELETE = "site.contracts.delete",
  SITE_CONTRACTS_EDIT = "site.contracts.edit",
  SITE_CLIENTS_MANAGE = "site.clients.manage",
  SITE_CLIENTS_VIEW = "site.clients.view",
  SITE_COMPLETED_VIEW = "site.completed.view",
  SITE_LOTS_VIEW = "site.lots.view",
  SITE_LOTS_CREATE = "site.lots.create",
  SITE_LOTS_EDIT = "site.lots.edit",
  SITE_LOTS_DELETE = "site.lots.delete",
  SITE_LOTS_RESCHEDULE = "site.lots.reschedule",
  SITE_LOTS_MAP_REPORT = "site.lots.map.report",
  SITE_LOTS_DOWNLOAD = "site.lots.download",
  SITE_LOTS_EXPORT = "site.lots.export",
  SITE_LOTS_ISSUE_VIEW = "site.lots.issue.view",
  SITE_LOTS_MERGE = "site.lots.merge",
  SITE_VISITS_VIEW = "site.visits.view",
  SITE_VISITS_INCOMPLETE = "site.visits.incomplete",
  SITE_VISITS_UPCOMING = "site.visits.upcoming",
  SITE_VISIT_CREATE = "site.visit.create",
  SITE_VISIT_EDIT = "site.visit.edit",
  SITE_VISIT_EDIT_UNLIMITED = "site.visit.edit.unlimited",
  SITE_VISIT_CANCEL = "site.visit.cancel",
  TECH_APP_ACCESS = "tech.app.access",
  TECH_VISIT_COMPLETE = "tech.visit.complete",
  TECH_VISIT_VIEW = "tech.visit.view",
  DASHBOARD_ADMIN = "dashboard.admin",
}

export interface UserPermissions {}
